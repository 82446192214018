import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'folder',
    redirectTo: 'folder/Inbox',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'basic',
    loadChildren: () =>
      import('./pages/basic/basic.module').then((m) => m.BasicPageModule),
  },
  {
    path: 'inbox',
    loadChildren: () =>
      import('./pages/tabs/inbox/inbox.module').then((m) => m.InboxPageModule),
  },
  {
    path: 'inbox',
    loadChildren: () =>
      import('./pages/tabs/inbox/inbox.module').then((m) => m.InboxPageModule),
  },
  {
    path: 'archived',
    loadChildren: () =>
      import('./pages/tabs/archived/archived.module').then(
        (m) => m.ArchivedPageModule
      ),
  },
  {
    path: 'compose',
    loadChildren: () =>
      import('./pages/tabs/compose/compose.module').then((m) => m.ComposePageModule),
  },
  {
    path: 'tabs',
    loadChildren: () =>
      import('./pages/tabs/tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'advanced',
    loadChildren: () =>
      import('./pages/advanced/advanced.module').then(
        (m) => m.AdvancedPageModule
      ),
  },
  {
    path: 'patients',
    loadChildren: () =>
      import('./pages/patient/patients/patients.module').then(
        (m) => m.PatientsPageModule
      ),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./pages/contact/contact.module').then((m) => m.ContactPageModule),
  },
  {
    path: 'notification',
    loadChildren: () =>
      import('./pages/notification/notification.module').then(
        (m) => m.NotificationPageModule
      ),
  },
  {
    path: 'physician',
    loadChildren: () =>
      import('./pages/specialist/physician/physician.module').then(
        (m) => m.PhysicianPageModule
      ),
  },
  {
    path: 'surgeon',
    loadChildren: () =>
      import('./pages/specialist/surgeon/surgeon.module').then(
        (m) => m.SurgeonPageModule
      ),
  },
  {
    path: 'anaesthetics',
    loadChildren: () =>
      import('./pages/specialist/anaesthetica/anaesthetica.module').then(
        (m) => m.AnaestheticaPageModule
      ),
  },
  {
    path: 'icu',
    loadChildren: () =>
      import('./pages/specialist/icu/icu.module').then((m) => m.IcuPageModule),
  },
  {
    path: 'patientInfo',
    loadChildren: () =>
      import('./pages/patient/patient-info/patient-info.module').then(
        (m) => m.PatientInfoPageModule
      ),
  },
  {
    path: 'submission',
    loadChildren: () =>
      import('./pages/submission/submission.module').then(
        (m) => m.SubmissionPageModule
      ),
  },
  {
    path: 'billing',
    loadChildren: () =>
      import('./pages/billing/billing.module').then((m) => m.BillingPageModule),
  },
  {
    path: 'lock',
    loadChildren: () =>
      import('./pages/auth/lock/lock.module').then((m) => m.LockPageModule),
  },
  {
    path: 'mbsLookup',
    loadChildren: () =>
      import('./pages/mbs-lookup/mbs-lookup.module').then(
        (m) => m.MbsLookupPageModule
      ),
  },
  {
    path: 'notificationDetails',
    loadChildren: () =>
      import('./pages/notification-details/notification-details.module').then(
        (m) => m.NotificationDetailsPageModule
      ),
  },
  {
    path: 'messageDetail',
    loadChildren: () =>
      import('./pages/tabs/message-detail/message-detail.module').then(
        (m) => m.MessageDetailPageModule
      ),
  },
  {
    path: 'submissionInfo',
    loadChildren: () =>
      import('./pages/submission-info/submission-info.module').then(
        (m) => m.SubmissionInfoPageModule
      ),
  },
  {
    path: 'ward-round',
    loadChildren: () => import('./pages/ward-round/ward-round.module').then(m => m.WardRoundPageModule)
  },
  {
    path: 'preferences-types',
    loadChildren: () => import('./pages/preferences/preferences-types/preferences-types.module').then(m => m.PreferencesTypesPageModule)
  },
  {
    path: 'confirmation-page',
    loadChildren: () => import('./pages/preferences/confirmation-page/confirmation-page.module').then(m => m.ConfirmationPagePageModule)
  },
  {
    path: 'add-hospital',
    loadChildren: () => import('./pages/preferences/add-hospital/add-hospital.module').then(m => m.AddHospitalPageModule)
  },
  {
    path: 'add-speciality',
    loadChildren: () => import('./pages/preferences/add-speciality/add-speciality.module').then(m => m.AddSpecialityPageModule)
  },
  {
    path: 'add-provider',
    loadChildren: () => import('./pages/preferences/add-provider/add-provider.module').then(m => m.AddProviderPageModule)
  },
  {
    path: 'banking-details',
    loadChildren: () => import('./pages/preferences/banking-details/banking-details.module').then(m => m.BankingDetailsPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'helpvideos',
    loadChildren: () => import('./pages/helpvideos/helpvideos.module').then( m => m.HelpvideosPageModule)
  },
  {
    path: 'web',
    loadChildren: () => import('./pages/web/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'webcontact',
    loadChildren: () => import('./pages/web/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'webhelp',
    loadChildren: () => import('./pages/web/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'websubmissions',
    loadChildren: () => import('./pages/web/submissions/submissions.module').then( m => m.SubmissionsPageModule)
  },
  {
    path: 'webcorrespondence',
    loadChildren: () => import('./pages/web/correspondence/correspondence.module').then( m => m.CorrespondencePageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
