import { Component } from '@angular/core';
import { ApiService } from './providers/apiService/api.service';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { ThemeDetection, ThemeDetectionResponse, } from '@awesome-cordova-plugins/theme-detection/ngx';
import { SplashScreen } from '@capacitor/splash-screen';
import { StorageService } from './providers/storageService/storage.service';
import { NativeMarket } from "@capacitor-community/native-market";
import { Capacitor } from "@capacitor/core";
import { register } from 'swiper/element';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  versionLatest = '';
  //********************************** */
  versionThis = '4.10.24'; //make sure to change this EVERY BUILD!! ALSO CHECK shortcode in medbill.com.au to match once live in stores... this should match the full build number in Ionic's Configuration tab.
  portalVersion = '1.6';
  //********************************** */
  iosID: string = 'id1059957191';
  androidID: string = 'com.medicalbilling.mbe';
  isIOS: boolean = false;
  isAndroid: boolean = false;
  lockTimer:number;

  constructor(
    private apiService: ApiService,
    public navCtrl: NavController,
    private themeDetection: ThemeDetection,
    public storageService: StorageService,
    private platform: Platform,
    private alertController: AlertController,
    private appVersion: AppVersion, 
  ) {
    this.initializeApp();
  }
  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();
      //console.log('platform: ',Capacitor.getPlatform());
      if (Capacitor.getPlatform() === "web") {
        
        this.storageService.set('portalVersion', this.portalVersion);
        this.storageService.set('appVersion', this.versionThis);
        this.navCtrl.navigateRoot('/web');
      }
      else
      {
        document.documentElement.classList.add('dark-mode');
        this.themeDetect();
        this.check4Update().then(() => {
          this.handleNavigation();  
        });
        
      }
      
    });
  }

  openAppStore() {
    let appId = this.iosID;
    if (Capacitor.getPlatform() === "android") {
      appId = this.androidID;
    }
    
    NativeMarket.openStoreListing({
      appId: appId,
    });
  }

  async check4Update() {
    //console.log('check4update');
    if (Capacitor.getPlatform() === "android") {
      this.isAndroid = true;
    }
    if (Capacitor.getPlatform() === "ios") {
      this.isIOS = true;
    }
    this.appVersion.getVersionNumber().then((res)=>{
      this.versionThis = res;
      //console.log('Ionic Config getVersionNumber(): ',res);
      
      const params = {
        platform:Capacitor.getPlatform()
      };      
      this.apiService
        .post('latestVersion',params)
        .subscribe(
          (res) => {
            if (res.error === false) {
              this.versionLatest = res.data.version;
              //console.log('This:'+this.versionThis+', Latest: '+this.versionLatest);
              //console.log('platform passed: ', res.data.platform);
              if (this.versionLatest > this.versionThis)
              {
                //console.log(this.versionThis +' < ' + this.versionLatest + ' so should present update')
                this.presentUpdateAlert();
              }
            }          
          },
          (err) => {           
          }
        );
      }, (err)=>{
        //console.log('Err: ',err, ' - setting default version to '+this.versionThis)
      });
      this.storageService.set('appVersion', this.versionThis);
  }

  async presentUpdateAlert()
  {
    //console.log('presentalert');
    const alert = await this.alertController.create({
      cssClass: 'updateAlert', 
      header: 'Update Available',
      mode:'ios',
      animated: true,
      message: '<span class=\'alertBold\'>Version '+ this.versionLatest +' is available!</span><br><br>You are running version ' + this.versionThis + '<br><br>It is important to keep your app up to date.  Click the update button below to go to your app store and update now!',
      buttons: [
        {
          text: 'UPDATE',
          cssClass: 'buttonUpdate',
          role: 'confirm',
          handler: () => {
            this.openAppStore();
          },
        },
        {
          text:'CLOSE',
          cssClass: 'buttonNotNow',
          role: 'cancel'
        },
      ],
    });

    await alert.present();
  }

  

  handleNavigation() {

    this.storageService
    .getObject('userInfo')
    .then((result) => {
      
      if (result != null) {
        this.navCtrl.navigateRoot('/lock');        
      }
      else
      {
        this.storageService
        .getObject('tutorialWatched').then((result) => {
          if (result == null) {
              this.navCtrl.navigateRoot('/tutorial');
          }
        })
        .catch((e) => {
        this.navCtrl.navigateRoot('/login');
        });
      }
    })
    .catch((e) => {
      this.navCtrl.navigateRoot('/login');
      // Handle errors here
    });

    /*
    //console.log('handlenavigation');

    //check if logged in
    this.apiService.post('isloggedin',[]).subscribe(
      (res) => {
        //console.log(res);
        if (res.error === false) {
          //go to lock
          this.navCtrl.navigateRoot('/lock');
        }
        else
        {

          //go to login
          this.navCtrl.navigateRoot('/login');
        }
      },
      (err) => {        
        this.navCtrl.navigateRoot('/login');
      }
    );
    */
  }



  themeDetect() {
    const that = this;
    this.themeDetection
      .isAvailable()
      .then((res: ThemeDetectionResponse) => {
        if (res.value) {
          this.apiService.darkmode = 'default';
          this.themeDetection
            .isDarkModeEnabled()
            .then((resp: ThemeDetectionResponse) => {
              //console.log('isDarkModeEnabled', resp);
              if (resp.value) {
                that.apiService.mode = 'dark';
                document.body.classList.add('dark');
              } else {
                that.apiService.mode = 'light';
                document.body.classList.remove('dark');
              }
            })
            .catch((error: any) => console.error(error));
        } else {
          this.apiService.darkmode = 'app';
        }
      })
      .catch((error: any) => console.error(error));
  }
}
